<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      :loading="loader"
      @submit="triggerModal = true"
      @close="$emit('close')"
      :disabled-button="!editState"
      submit="Update"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <div class="flex w-full justify-between items-center">
          <div class="flex">
            <p class="text-darkPurple">View Sweep Account</p>
          </div>
          <div class="flex cursor-pointer gap-1" @click="checkPrivilege">
            <icon
              icon-name="icon-edit"
              size="xs"
              class="inline-block text-blueCrayola"
            />
            <div class="flex flex-col justify-center">
              <p class="text-blueCrayola text-sm">Edit</p>
            </div>
          </div>
        </div>
      </template>
      <div class="flex flex-col items-start gap-3">
        <CSelect
          class="w-full mb-2"
          placeholder="--Select--"
          label="Bank"
          :rules="['required']"
          :options="banks"
          v-model="customer.bankCode"
          @input="validateNumber(customer.accountNumber)"
          :disabled="!editState"
        />

        <CText
          class="w-full mb-2"
          placeholder="--Enter--"
          label="Account Number"
          :rules="numberRule"
          v-model="customer.accountNumber"
          type="number"
          :disabled="!editState"
        />

        <small v-if="loadingAccount" class="text-xs">Loading...</small>
        <small class="text-red-700 font-bold" v-if="error">{{
          `${error}`
        }}</small>
        <small class="text-flame font-bold" v-if="accountName">{{
          ` ${accountName} `
        }}</small>
      </div>

      <Modal v-if="triggerModal">
        <Card class="p-5 flex flex-col max-w-md">
          <div class>
            <div class="font-bold ml-4 my-5 text-center">
              <p>
                You are attempting to change your sweep account. Please confirm.
              </p>
            </div>
            <div class="flex">
              <Button
                class="bg-dynamicBackBtn text-white"
                @click="handleSubmit(onSubmit)"
                :disabled="disabled"
              >
                Yes, Update
              </Button>
              <Button
                background-color="none"
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
                @click="triggerModal = false"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import RightSideBar from "@/components/RightSideBar";
import Modal from "@/components/Modal";
import Card from "@/components/Card";
import Button from "@/components/Button";

export default {
  name: "ViewSweepAccount",
  components: {
    ValidationObserver,
    RightSideBar,
    Modal,
    Card,
    Button,
    CText,
    CSelect,
  },
  data() {
    return {
      numberRule: [
        "required",
        {
          name: "number",
          rule: (v) => this.validateNumber(v),
        },
      ],
      loader: false,
      error: false,
      accountName: null,
      loadingAccount: false,
      customer: {
        bankCode: this.accountInfo.bankCode,
        accountNumber: this.accountInfo.accountNumber,
        orgId: null,
        userId: null,
        sweepId: this.accountInfo.id,
      },
      triggerModal: false,
      disabled: false,
      editState: false,
    };
  },

  props: {
    accountInfo: {
      type: Object,
      default: () => {},
    },
    banks: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    async checkPrivilege() {
      try {
        await this.$handlePrivilege("bankAccountHR", "editSweepAccount");
        this.editState = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    validateNumber(value) {
      this.error = "";
      this.accountName = null;
      if (value.toString().length === 10) {
        if (this.accountName === null) {
          this.loadingAccount = true;
        }
        this.handleAccountChange(value);
        return true;
      }
      return "Account number must be 10 numbers";
    },

    handleAccountChange(value) {
      this.$_checkAccountNo({
        bankCode: this.customer.bankCode,
        acctNumber: value,
      })
        .then((result) => {
          if (result.status === 200) {
            this.error = "";
            this.loadingAccount = false;
            this.accountName = result.data.data.account_name;
          }
        })
        .catch(() => {
          this.loadingAccount = false;
          this.accountName = null;
          this.error = "Incorrect Account number";
        });
    },

    onSubmit() {
      this.customer.userId = this.$AuthUser.id;
      this.customer.orgId = this.$OrgData.id;
      this.disabled = true;
      this.$_addSweepAccount(this.customer)
        .then(() => {
          this.$emit("close");
          this.$toasted.success("Sweep Account updated successfully", {
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toasted.error("Error in updating sweep account", {
            duration: 6000,
          });
          this.disabled = false;
        });
    },
  },
};
</script>
