<template>
  <div>
    <ValidationObserver rules="required" v-slot="{ handleSubmit }">
      <RightSideBar
        :loading="loader"
        @submit="handleSubmit(authenticateTransaction)"
        @close="$emit('close')"
        submit="Confirm"
        button-class="bg-dynamicBackBtn text-white"
      >
        <template v-slot:title>
          <p class="text-darkPurple">Transfer</p>
        </template>
        <div class="flex flex-col items-start gap-5">
          <CText
            class="w-full mb-2"
            placeholder="--Enter--"
            label="Account Balance (N)"
            :rules="['required']"
            v-model="customer.accountBalance"
            type="number"
            :disabled="true"
          />

          <CText
            class="w-full mb-2"
            placeholder="--Enter--"
            label="Transfer Amount (N)"
            :rules="numberRule"
            v-model="sweepPay.amount"
            type="number"
          />

          <small class="font-bold">{{ ` ${messageAlert} ` }}</small>

          <div class="my-1 w-full p-4 bg-ghostWhite">
            <p class="flex text-base">A transaction charge of N200 applies.</p>
          </div>
        </div>
      </RightSideBar>
    </ValidationObserver>

    <Modal v-if="showPasswordModal">
      <Card class="p-5 flex flex-col max-w-lg">
        <div class>
          <p class="font-extrabold text-lg my-5 text-left">
            You need to be authenticated to complete transaction.
          </p>
          <p class="text-romanSilver font-semibold text-left mt-5 mb-3">
            Type in your password
          </p>
          <div class="my-5">
            <CText
              placeholder="--Enter--"
              label="Password"
              class="w-full mt-2"
              v-model="password"
              :type="decryptPassword ? '' : 'password'"
            />
            <checkbox
              label="Show Password"
              checkbox-size="width:16px; height: 16px; margin-top:4px"
              class="flex justify-start items-left my-4"
              v-model="decryptPassword"
            />
          </div>
          <div class="flex justify-end">
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn"
              width="7.875rem"
              @click="showPasswordModal = false"
            >
              Cancel
            </Button>
            <Button
              width="7.875rem"
              class="bg-dynamicBackBtn ml-4 text-white"
              :disabled="disableBtn"
              @click="checkPassword"
            >
              Submit
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import RightSideBar from "@/components/RightSideBar";
import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox";
import Modal from "@/components/Modal";
import Card from "@/components/Card";

export default {
  name: "TransferModal",
  components: {
    ValidationObserver,
    RightSideBar,
    CText,
    Button,
    Card,
    Checkbox,
    Modal,
  },
  data() {
    return {
      numberRule: [
        "required",
        {
          name: "number",
          rule: (v) => this.totalTransferable(v),
        },
      ],
      loader: false,
      error: false,
      accountName: null,
      password: "",
      loadingAccount: false,
      customer: {
        accountBalance: parseFloat(this.totalBalance.replace(/,/g, "")),
      },
      sweepPay: {
        sweepId: this.details.id,
        userId: "",
        currency: this.details.currency,
        amount: "",
        orgId: this.details.orgId,
        recipientCode: this.details.recipientCode,
      },
      disabled: false,
      disableBtn: false,
      amount: "",
      messageAlert: "",
      showPasswordModal: false,
      decryptPassword: false,
    };
  },

  props: {
    totalBalance: {
      type: String,
      default: "",
    },
    details: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    totalTransferable(amount) {
      const trimBalance = Number(
        this.totalBalance.replace(/\.00$/, "").replaceAll(",", "")
      );
      if (amount > 200 && amount <= trimBalance) {
        const difference = Number(amount) - 200;
        this.messageAlert = `Total Transferable : ${this.convertToCurrency(
          amount
        )} - ${this.convertToCurrency(200)} charge = ${this.convertToCurrency(
          difference
        )}`;
        return true;
      }
      if (amount > trimBalance) {
        this.messageAlert = "";
        return "Transfer Amount cannot exceed Account Balance";
      }
      this.messageAlert = "";
      return "Transfer amount is too low";
    },

    authenticateTransaction() {
      this.showPasswordModal = true;
    },

    checkPassword() {
      this.disableBtn = true;
      this.$_checkPassword({
        userId: this.$AuthUser.id,
        password: this.password,
      })
        .then(() => {
          this.showPasswordModal = false;
          this.password = "";
          this.decryptPassword = false;
          this.disableBtn = false;
          this.onSubmit();
        })
        .catch(() => {
          this.$toasted.error("Password is incorrect", { duration: 5000 });
          this.disableBtn = false;
        });
    },

    onSubmit() {
      this.sweepPay.userId = this.$AuthUser.id;
      this.$_paySweepAccount(this.sweepPay)
        .then(() => {
          this.$emit("close");
          this.$toasted.success("Transfer was successful", {
            duration: 3000,
          });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, {
            duration: 3000,
          });
        });
    },
  },
};
</script>
