<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      @submit="handleSubmit(onSubmit)"
      @close="$emit('close')"
      submit="Save"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Sweep Account</p>
      </template>

      <p class="text-base font-semibold my-4">
        Your sweep account allows you transfer back your virtual NUBAN balance
        to your regular bank account. A transaction fee of N200 applies.
      </p>

      <div class="flex flex-col items-start gap-3">
        <CSelect
          class="w-full mb-2"
          placeholder="--Select--"
          label="Bank"
          :rules="['required']"
          :options="banks"
          v-model="customer.bankCode"
          @input="validateNumber(customer.accountNumber)"
        />

        <CText
          class="w-full mb-2"
          placeholder="--Enter--"
          label="Account Number"
          :rules="numberRule"
          v-model="customer.accountNumber"
          type="number"
        />

        <small v-if="loadingAccount" class="text-xs">Loading...</small>
        <small class="text-red-700 font-bold" v-if="error">{{
          `${error}`
        }}</small>
        <small class="text-flame font-bold" v-if="accountName">{{
          ` ${accountName} `
        }}</small>
      </div>

    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import RightSideBar from "@/components/RightSideBar";

export default {
  name: "ViewSweepAccount",
  components: {
    ValidationObserver,
    RightSideBar,
    CText,
    CSelect,
  },
  data() {
    return {
      numberRule: [
        "required",
        {
          name: "number",
          rule: (v) => this.validateNumber(v),
        },
      ],
      loader: false,
      error: false,
      accountName: null,
      loadingAccount: false,
      customer: {
        bankCode: "",
        accountNumber: "",
        orgId: null,
        userId: null,
        sweepId: null,
      },
      disabled: false
    };
  },
  props: {
    banks: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    validateNumber(value) {
      this.error = "";
      this.accountName = null;
      if (value.toString().length === 10) {
        if (this.accountName === null) {
          this.loadingAccount = true;
        }
        this.handleAccountChange(value);
        return true;
      }
      return "Account number must be 10 numbers";
    },

    handleAccountChange(value) {
      this.$_checkAccountNo({
        bankCode: this.customer.bankCode,
        acctNumber: value,
      })
        .then((result) => {
          if (result.status === 200) {
            this.error = "";
            this.loadingAccount = false;
            this.accountName = result.data.data.account_name;
          }
        })
        .catch(() => {
          this.loadingAccount = false;
          this.accountName = null;
          this.error = "Incorrect Account number";
        });
    },

    onSubmit() {
      this.customer.userId = this.$AuthUser.id;
        this.customer.orgId = this.$OrgData.id;
        this.disabled = true;
      this.$_addSweepAccount(this.customer)
        .then(() => {
          this.$emit("close");
          this.$toasted.success("Sweep Account created successfully", {
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toasted.error("Error in updating sweep account", {
            duration: 6000,
          });
            this.disabled = false;
        });
    },
  },
};
</script>
