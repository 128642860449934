<template>
  <div>
    <card>
      <div class="flex justify-between  p-5">
        <div class="flex gap-3" />
        <div class="flex gap-3">
          <div class="mt-5 mb-5 tracking-wide">
            If you have not setup a direct debit account with Paystack
            <a href="#" class="text-flame">click here for instruction</a>
          </div>
          <div class="mt-5 mb-5">
            <paystack
              :amount="amount"
              :email="email"
              :metadata="metadata"
              :paystackkey="paystackKey"
              :callback="callback"
              :channels="['direct_debit']"
              :close="close"
              :embed="false"
            >
              <Button class="bg-dynamicBackBtn text-white rounded ">
                Add Account
              </Button>
            </paystack>
          </div>
        </div>
      </div>
      <div class="border border-dashed h-0 p-0 border-romanSilver" />
      <card-footer class="p-5" />
    </card>
    <div>
      <Table
        v-if="directDebits.length !== 0 || loading"
        :headers="AccountsHeader"
        :items="directDebits"
        aria-label="direct-debit"
        class="w-full p-5 mb-4"
        :has-number="false"
        :loading="loading"
      >
        <template v-slot:item="{ item }">
          <div v-if="item.bankName" class=" capitalize">
            {{ item.data.bankName }}
          </div>
          <div v-if="item.accountNumber">
            {{ item.data.accountNumber }}
          </div>
          <div v-if="item.status" class=" capitalize ">
            <span class="uppercase">{{ item.data.status }}</span>
          </div>
          <div v-if="item.isDefault" class=" capitalize text-center">
            <span v-if="item.data.isDefault"> YES</span>
            <span v-else> NO </span>
          </div>
          <div v-if="item.id">
            <Menu left top="-15" class="p-0" margin="30">
              <template v-slot:title>
                <icon
                  icon-name="more_icon"
                  size="xsm"
                  class="-mt-1"
                  style="padding: 3px 10px; width: 36px; height: 36px;"
                />
              </template>
              <div style="width: 158px; height: 80px" class=" py-3 px-2 ">
                <div
                  class="flex py-1 px-3 h-8 cursor-pointer"
                  @click="editLocation(item.data)"
                >
                  <icon
                    icon-name="icon-active"
                    class-name="text-blueCrayola mr-2"
                    size="xs"
                  />
                  <p class="pt-1" style="font-size: 14px;">
                    Make Default
                  </p>
                </div>
              </div>
            </Menu>
          </div>
        </template>
      </Table>
      <div
        class="w-full h-auto flex flex-col"
        style="align-items: center"
        v-else
      >
        <div
          class="w-5/12 h-auto mt-5 mb-5 flex"
          style="justify-content: center"
        >
          <!-- <img src="@/assets/images/Earnings-Illustration.png" alt="" /> -->
        </div>
        <div class="w-8/12 h-auto text-center">
          <p class="mt-5 mb-5 tracking-wide">
            You current do not have any direct debit account set up. To add your
            direct debit account we need to debit N100 from you account. This
            amount will be reversed back to you once successful.
          </p>
          <p class="mt-5">
            <paystack
              :amount="amount"
              :email="email"
              :metadata="metadata"
              :paystackkey="paystackKey"
              :callback="callback"
              :channels="['direct_debit']"
              :close="close"
              :embed="false"
            >
              <button class="flex m-auto items-center">
                <Icon
                  size="xsm"
                  icon-name="icon-plus"
                  class-name="mt-1 text-flame"
                />
                <p class="ml-3 mt-1 font-semibold text-flame">
                  Add Account
                </p>
              </button>
            </paystack>
          </p>

          <p class="mt-5 mb-5 tracking-wide">
            If you have not setup a direct debit account with Paystack
            <a href="#" class="text-flame">click here for instruction</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import paystack from "vue-paystack";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Button from "@/components/Button";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import Menu from "@/components/Menu";

export default {
  name: "DirectDebitAccounts",
  components: {
    Table,
    Button,
    CardFooter,
    Card,
    Menu,
    paystack
  },
  data() {
    return {
      loading: true,
      paystackKey: process.env.VUE_APP_PAYSTACK_LIVE_PUBLIC_KEY, // paystack public key
      email: null, // Organization email
      amount: 10000, // in kobo
      currentTab: "Direct Debit Accounts",
      tab: ["Direct Debit Accounts", "Associations"],
      directDebits: [],
      metadata: {},
      AccountsHeader: [
        { title: "Bank Name", value: "bankName", width: 30 },
        { title: "Account Number", value: "accountNumber", width: 35 },
        { title: "Setup Status", value: "status", width: 30, align: "center" },
        { title: "Default", value: "isDefault", width: 30 },
        { title: "", value: "id", image: true }
      ]
    };
  },
  methods: {
    getAccounts() {
      this.$_getAllDirectDebitAccounts().then(result => {
        this.directDebits = result.data.data;
        this.loading = false;
      });
    },
    callback(response) {
      // verify direct  debit payment
      // eslint-disable-next-line no-console
      console.log(response);
      this.$_addDirectDebitAccount({
        paymentReference: response.reference
      }).then(result => {
        // eslint-disable-next-line no-console
        console.log(result);
        this.getAccounts();
      });
    },
    close() {}
  },
  computed: {},
  mounted() {
    this.getAccounts();
    this.email = this.$OrgData.email;
    this.metadata = {
      type: "direct_debit_account",
      orgId: this.$orgId,
      custom_field: {
        type: "direct_debit_account",
        orgId: this.$orgId
      }
    };
  }
};
</script>
