<template>
  <div>
    <div class="py-6 w-full px-3">
      <h1 class="text-xl text-left font-bold flex mb-2 -mt-2">Bank Accounts</h1>
      <div class="w-full h-0 p-0 mb-4" style="border: 0.2px solid #d6dbe4" />
      <div  v-if="showSettings === true">
      <Tabs
        class="mt-2 mx-2"
        :tabs="
          url
            ? ['Virtual NUBAN Accounts', 'Direct Debit']
            : ['Virtual NUBAN Accounts']
        "
        :active-tab="currentTab"
        :border="true"
        @currentTab="currentTab = $event"
      />
      <NubanAccounts v-if="currentTab === 'Virtual NUBAN Accounts' && showSettings === true" />
      <DirectDebit v-if="currentTab === 'Direct Debit' && showSettings === true" />
      </div>
      <ErrorPage v-else />
    </div>
  </div>
</template>

<script>
import Tabs from "@scelloo/cloudenly-ui/src/components/tab";
import ErrorPage from "@/modules/Admin/error403";
import NubanAccounts from "./NubanAccounts";
import DirectDebit from "./DirectDebit";

export default {
  name: "BankAccounts",
  components: {
    Tabs,
    NubanAccounts,
    ErrorPage,
    DirectDebit,
  },
  data() {
    return {
      paymentsData: [],
      url: process.env.NODE_ENV === "development",
      directDebitData: [],
      currentTab: "Virtual NUBAN Accounts",
      showSettings: true
      // tab: ["Virtual NUBAN Accounts", "Direct Debit"]
    };
  },
  methods: {},
  async mounted() {
    try {
      await this.$handlePrivilege("bankAccountHR", "accessBankAccountSettings");
      this.showSettings = true;
    } catch {
      this.showSettings = false
    }
  },
};
</script>
